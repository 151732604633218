<template>
  <div>
    <div id="gmapHome"></div>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="blue"
        spinner="mini-spinner"
      />
      <ServerError v-if="ServerError" />

      <!-- <Details/> -->
      <!-- <span>{{snareData}};;;;;;;;kkkkkkkk</span> -->
    </div>
  </div>
</template>
<script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBTkuPaji75FkC_WBegL4cupA9_HJWNAuE&libraries=visualization,drawing">
</script>
<script>
import axios from "axios";
// import Details from './dataPage';
export default {
  props: ["snareData", "Trails"],
  // components:
  // {
  // Details
  // },
  data() {
    return {
      defaultLAt: 0,
      defaultLON: 0,
      icons: "",
      totalLength: 0,
      page: 1,
      appLoading: false,
      ServerError: false,
      user: [],
      records: [],
      gmap: null,
      pagelength: 0,
      allData: [],
      //map data
      latt: null,
      lonn: null,
      CORD: {
        lat: "",
        lng: "",
      },

      flightPlanCoordinates: [],
      flight: [],
    };
  },
  watch: {
    snareData() {
      this.snareList();
    },
  },
  mounted() {
    // this.getData();
    this.getMap();
    // this.snareList();
  },
  methods: {
    snareList() {
      console.log("im working");
      console.log("snareLength", this.snareData.length);
      for (let i = 0; i < this.snareData.length; i++) {
        console.log("this.snareData", this.snareData);
        this.latt = this.snareData[i].snareLocation[1];
        this.lonn = this.snareData[i].snareLocation[0];
        let l = i + 1;
        this.icons = {
          // url: require("../../assets/images/markers/greenMarker.png"),
          url: require("../../assets/images/markers/redMarker.png"),
          size: new google.maps.Size(100, 60),
          scaledSize: new google.maps.Size(20, 30),
          anchor: new google.maps.Point(0, 50),
        };
        this.marker = new google.maps.Marker({
          position: { lat: this.latt, lng: this.lonn },
          map: this.gmap,
          icon: this.icons,
        });

        this.marker.addListener("click", (eve) => {
          infowindow.open(this.map3, this.marker);
          infowindow.setPosition(eve.latLng);
        });
      }

      // for (let A = 0; A < this.Trails.length; A++) {
      //   this.flightPlanCoordinates = [];
      //   this.flight = [];
      //   //  this.Trails=[];

      //   for (let i = 0; i < this.Trails[A].length; i++) {
      //     this.CORD.lat = this.Trails[A][i].location[1];
      //     this.CORD.lng = this.Trails[A][i].location[0];
      //     this.flight.push(JSON.parse(JSON.stringify(this.CORD)));
      //     console.log("this.CORD=", this.CORD);
      //     this.CORD.lat = "";
      //     this.CORD.lon = "";
      //   }
      //   console.log("this.flight=", this.flight);
      //   this.flightPlanCoordinates = this.flight;
      //   const flightPath = new google.maps.Polyline({
      //     path: this.flightPlanCoordinates,
      //     geodesic: true,
      //     strokeColor: "#5282e3",
      //     strokeOpacity: 1.0,
      //     strokeWeight: 5,
      //   });
      //   flightPath.setMap(this.gmap);
      // }
    },

    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
        return month + "/" + day + "/" + year;
      }
    },

    getMap() {
      this.gmap = new google.maps.Map(document.getElementById("gmapHome"), {
        center: { lat: 22.5260093, lng: 76.8144186 },
        mapTypeId: "satellite",
        zoom: 4,
      });

      if (this.snareData.length > 0) {
        this.snareList();
      }
    },
  },
};
</script>

<style scoped>
#gmapHome {
  height: 400px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
</style>

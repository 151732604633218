<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap>
      <v-flex xs12 pa-2>
        <span> <h3 style="border-bottom: 3px solid grey">DASH BOARD</h3></span>
      </v-flex>
      <v-flex xs12 sm6 lg4 pa-2>
        <v-card elevation="2" class="pa-1" to ="/commonmap?searchCall=Snare">
          <!-- <v-img height="80px" contain src="../../assets/images/icons/icons8-trap-100.png"> -->
          <v-img
            height="77px"
            contain
            src="../../assets/images/icons2/Snare.png"
          >
          </v-img>
          <v-layout wrap pa-3 pt-lg-6 pb-lg-2>
            <v-flex xs12 text-center align-self-center>
              <span class="subhead bold" style="text-transform: uppercase"
                >Snares Removed</span
              >
              &nbsp;
              <!-- <span class="bold" style="color: green; font-size: 30px">{{usercount}}</span> -->
            </v-flex>
            <v-flex
              class="bold"
              xs12
              text-center
              style="font-size: 25px; color: #313563"
            >
              {{ numberOfRemovedSnare }}

            
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg4 pa-2>
        <v-card elevation="2" class="pa-1" to ="/commonmap?searchCall=Trap">
          <!-- <v-img height="80px" contain src="../../assets/images/icons/icons8-users-100.png"> -->
          <v-img
            height="74px"
            contain
            src="../../assets/images/icons2/Bear Trap.png"
          >
          </v-img>
          <v-layout wrap pa-3 pt-lg-6 pb-lg-2>
            <v-flex xs12 text-center align-self-center>
              <span class="subhead bold" style="text-transform: uppercase"
                >Traps Removed
              </span>
              &nbsp;
              <!-- <span class="bold" style="color: green; font-size: 30px">{{usercount}}</span> -->
            </v-flex>
            <v-flex
              class="bold"
              xs12
              text-center
              style="font-size: 25px; color: #313563"
            >
                {{ numberOfRemovedTraps }}
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg4 pa-2>
        <v-card elevation="2" class="pa-1" to ="/commonmap?searchCall=HuntingTool">
        <!-- <v-card elevation="2" class="pa-1" > -->

          <v-img
            height="80px"
            contain
            src="../../assets/images/icons2/icons8-archer-96.png"
          >
          </v-img>
          <v-layout wrap pa-3 pt-lg-6 pb-lg-2>
            <v-flex xs12 text-center align-self-center>
              <span class="subhead bold" style="text-transform: uppercase"
                >Hunting Tools Recovered</span
              >
              &nbsp;
              <!-- <span class="bold" style="color: green; font-size: 30px">{{recordcount}}</span> -->
            </v-flex>
            <v-flex
              class="bold"
              xs12
              text-center
              style="font-size: 25px; color: #313563"
            >
              {{hundingToolsRemoved}}
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <v-flex xs12 sm6 lg4 pa-2>
        <v-card elevation="2" class="pa-1">
          <!-- <v-img  height="80px" contain src="../../assets/images/icons/icons8-wildebeest-100.png"> -->
          <v-img
            height="80px"
            contain
            src="../../assets/images/icons2/Foot 2.png"
          >
          </v-img>
          <v-layout wrap pa-3 pt-lg-6 pb-lg-2>
            <v-flex xs12 text-center align-self-center>
              <span class="subhead bold" style="text-transform: uppercase"
                >Kilometre Walked</span
              >
              &nbsp;
              <!-- <span class="bold" style="color: green; font-size: 30px">{{recordcount}}</span> -->
            </v-flex>
            <v-flex
              class="bold"
              xs12
              text-center
              style="font-size: 25px; color: #313563"
            >
              {{kilometersWalked}}
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg4 pa-2>
        <v-card elevation="2" class="pa-1">
          <!-- <v-img  height="80px" contain src="../../assets/images/icons/icons8-hours-100.png"> -->
          <v-img
            height="80px"
            contain
            src="../../assets/images/icons2/icons8-watch-96.png"
          >
          </v-img>
          <v-layout wrap pa-3 pt-lg-6 pb-lg-2>
            <v-flex xs12 text-center align-self-center>
              <span class="subhead bold" style="text-transform: uppercase"
                >Hours Dedicated</span
              >
              &nbsp;
              <!-- <span class="bold" style="color: green; font-size: 30px">{{recordcount}}</span> -->
            </v-flex>
            <v-flex
              class="bold"
              xs12
              text-center
              style="font-size: 25px; color: #313563"
            >
             {{hoursDedicated}}
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg4 pa-2>

        <!-- /animalrescue -->
          <router-link :to="'/animalrescue'" style="text-decoration:none">
        <v-card elevation="2" class="pa-1"  to ="/commonmap?searchCall=Rescued">
          <v-img
            height="80px"
            contain
            src="../../assets/images/icons2/icons8-cat-footprint-100.png"
          >
          </v-img>
          <v-layout wrap pa-3 pt-lg-6 pb-lg-2>
            <v-flex xs12 text-center align-self-center>
              <span class="subhead bold" style="text-transform: uppercase"
                >Animals Rescued</span
              >
              &nbsp;
              <!-- <span class="bold" style="color: green; font-size: 30px">{{recordcount}}</span> -->
            </v-flex>
            <v-flex
              class="bold"
              xs12
              text-center
              style="font-size: 25px; color: #313563"
            >
              {{animalRescued}}
            </v-flex>
          </v-layout>
        </v-card>
          </router-link>
      </v-flex>
      <v-flex xs12 sm6 lg4 pa-2>
        <v-card elevation="2" class="pa-1">
          <v-img
            height="80px"
            contain
            src="../../assets/images/icons/icons8-walking-100.png"
          >
          </v-img>
          <v-layout wrap pa-3 pt-lg-6 pb-lg-2>
            <v-flex xs12 text-center align-self-center>
              <span class="subhead bold" style="text-transform: uppercase"
                >Total number Of Walks</span
              >
              &nbsp;
              <!-- <span class="bold" style="color: green; font-size: 30px">{{recordcount}}</span> -->
            </v-flex>
            <v-flex
              class="bold"
              xs12
              text-center
              style="font-size: 25px; color: #313563"
            >
              {{ walkCount }}
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <v-flex xs12 sm6 lg4 pa-2>
           <router-link :to="'/trainings'" style="text-decoration:none">
        <v-card elevation="2" class="pa-1">
          <!-- <v-img  height="80px" contain src="../../assets/images/icons/icons8-rescue-100.png"> -->
          <v-img
            height="80px"
            contain
            src="../../assets/images/icons2/Picture1.png"
          >
          </v-img>
          <v-layout wrap pa-3 pt-lg-6 pb-lg-2>
            <v-flex xs12 text-center align-self-center>
              <span class="subhead bold" style="text-transform: uppercase"
                >Officials Trained</span
              >
              &nbsp;
            </v-flex>
            <v-flex
              class="bold"
              xs12
              text-center
              style="font-size: 25px; color: #313563"
            >
              {{trainigs}}
            </v-flex>
          </v-layout>
        </v-card>
           </router-link>
      </v-flex>

      <v-flex xs12 sm6 lg4 pa-2>
        <router-link :to="'/userlist'" style="text-decoration:none">
        <v-card elevation="2" class="pa-1">
          <!-- <v-img height="80px" contain src="../../assets/images/icons/icons8-users-100.png"> -->
          <v-img
            height="80px"
            contain
            src="../../assets/images/icons2/icons8-users-96 (1).png"
          >
          </v-img>
          <v-layout wrap pa-3 pt-lg-6 pb-lg-2>
            <v-flex xs12 text-center align-self-center>
              <span class="subhead bold" style="text-transform: uppercase"
                >Total Number of users
              </span>
              &nbsp;
              <!-- <span class="bold" style="color: green; font-size: 30px">{{usercount}}</span> -->
            </v-flex>
            <v-flex
              class="bold"
              xs12
              text-center
              style="font-size: 25px; color: #313563"
            >
              {{ userCount }}
            </v-flex>
          </v-layout>
        </v-card>
        </router-link>
      </v-flex>
      

      <v-flex xs12 pa-2>
        <span style="text-left">
          <h3 style="border-bottom: 3px solid grey">SNARE LOCATIONS</h3></span
        >
      </v-flex>
      <v-flex xs12 pa-3>
        <LocationMap :snareData="snareLocation" :Trails ="Trails" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../store";
import LocationMap from "./boardMap";
export default {
  components: {
    LocationMap,
  },
  data() {
    return {
      Trails:null,
      userCount:null,
      ServerError: false,
      appLoading: false,
      userList: [],
      recordcount: 0,
      usercount: 0,
      dashData: [],
      numberOfRemovedSnare: '',
      numberOfRemovedTraps: '',
      numberOfSnare: 0,
      numberOfTraps: 0,
      walkCount: 0,
      snareLocation: [],
      hundingToolsRemoved:null,
      kilometersWalked:null,
      hoursDedicated:null,
      animalRescued:null,
      trainigs:null
    };
  },

  // computed: {
  //   userName() {
  //     return store.state.USDD;
  //   },
  // },
  // created()
  // {
  //   this.getData();
  // },

  // beforeMount()
  // {
  //    this.getData();
  // },

  mounted() {
    this.getData();
  },

  methods: {
    addd() {
      store.commit("USD", "this is the second valur");
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/home",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
       
          if (response.data.status) {
            this.recordcount = response.data.recordcount;
            this.usercount = response.data.usercount;
            this.numberOfRemovedSnare = response.data.numberOfRemovedSnare;
            this.numberOfRemovedTraps = response.data.numberOfRemovedTraps;
            this.numberOfSnare = response.data.numberOfSnare;
            this.numberOfTraps = response.data.numberOfTraps;
            this.walkCount = response.data.walkCount;
            this.snareLocation = response.data.snareLocation;
            this.hundingToolsRemoved = response.data.hundingToolsRemoved;
            this.kilometersWalked = response.data.kilometersWalked;
            this.hoursDedicated = response.data.hoursDedicated;
            this.animalRescued = response.data.animalRescued;
            this.trainigs = response.data.trainigs;
            this.userCount =  response.data.userCount
            // this.Trails = response.data.walkData;
            this.Trails = response.data.walkData;





          }
          this.appLoading = false;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>